<template>
  <section class="el-login-login-route">
    <roleList ref="roleList" @switchComponent="switchComponent"></roleList>
    <div class="main">
      <div class="logo">
        <img src="@/assets/logo.png" alt="logo" />
      </div>
      <div class="title">江西省人民陪审员选任系统</div>
      <el-form :model="form.data" :rules="form.rules" ref="ruleForm" class="demo-ruleForm">
        <el-form-item prop="username">
          <el-input type="test" v-model="form.data.username" placeholder="请输入用户名" clearable maxlength="100">
            <i slot="prefix" class="el-input__icon el-input__icon2 icon icon-user"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            show-password
            v-model="form.data.password"
            placeholder="请输入密码"
            clearable
            maxlength="100"
          >
            <i slot="prefix" class="el-input__icon el-input__icon2 icon icon-lock"></i>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')" :loading="loading">登录</el-button>
          <el-button type="text" class="backPassword" @click="backPassword" :loading="loading">找回密码</el-button>
        </el-form-item>
      </el-form>
    </div>
  </section>
</template>

<script>
import Login from '@/api/login';
import roleList from './roleList.vue';
export default {
  name: 'login',
  props: {},
  components: {
    roleList,
  },
  data() {
    return {
      form: {
        data: {
          username: '',
          password: '',
        },
        rules: {
          username: [{ required: true, message: '请输入账号', trigger: 'change' }],
          password: [{ required: true, message: '请输入密码', trigger: 'change' }],
        },
      },
      loading: false,
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            let res = await Login.userlogin(this.form.data);

            let { token, user } = res;
            this.$store.commit('setUser', user);
            this.$store.commit('setToken', token);
            this.$myset('userInfo', res);
            this.CheckPerfectInfo();
            // 目前没有判断是否完善资料
            this.loading = false;
            window.removeEventListener('keydown', this.enter);
          } catch (e) {
            console.log(e);
            this.loading = false;
          }
        } else {
          console.log('提交失败');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 找回密码
    backPassword() {
      console.log('找回密码');
    },
    async CheckPerfectInfo() {
      let role = await Login.identityList({ page: 1, size: 30 });
      console.log('role');
      console.log(role);
      console.log('role');
      if (role.items.length === 1) {
        this.$refs.roleList.getRole();
        this.$refs.roleList.getRoleRouter(role.items[0]);
      } else {
        this.$refs.roleList.toggle(true);
        this.$refs.roleList.getRole();
      }
    },
    switchComponent(name) {
      this.$emit('switchComponent', name);
    },
    enter(e) {
      if (e.key === 'Enter') {
        return this.submitForm('ruleForm');
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.enter);
  },
  async created() {
    let { token } = this.$store.getters;
    if (token) {
      return this.CheckPerfectInfo();
    }
  },
  computed: {},
  watch: {},
  beforeDestroy() {
    window.removeEventListener('keydown', this.enter);
  },
};
</script>

<style scoped lang="scss">
.el-login-login-route {
  background: repeating-linear-gradient(90deg, #4859fe, #0acefe);
  display: flex;
  height: 100vh;
  .main {
    margin: auto;
    background-color: #fff;
    border-radius: 20px;
    text-align: center;
    width: 420px;
    height: 580px;
    padding: 0 40px;
    .logo {
      padding-top: 55px;
      padding-bottom: 20px;
      img {
        display: inline-block;
        width: 100px;
        height: 100px;
      }
    }
    .title {
      color: rgb(0, 121, 254);
      padding-bottom: 47px;
      font-size: 28px;
      font-weight: bold;
    }

    ::v-deep .el-input__inner {
      height: 50px;
      line-height: 50px;
      text-indent: 10px;
    }
    .el-input__icon2 {
      height: 50px;
      line-height: 50px;
      font-size: 25px;
      padding-left: 5px;
    }
    .backPassword {
      float: right;
    }
    .el-button:nth-of-type(1) {
      width: 100%;
    }
  }
}
</style>
