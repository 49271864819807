<template>
  <components :is="componentName" @switchComponent="switchComponent"></components>
</template>

<script>
import login from './components/login';
import perfectInfo from './components/perfectInfo';
import validate from './components/validate';
export default {
  name: 'router-login',
  props: {},
  components: {
    login,
    perfectInfo,
    validate,
  },
  data() {
    return {
      componentName: 'login',
    };
  },
  methods: {
    switchComponent(name) {
      this.componentName = name;
    },
  },
  created() {
    let { token, accountid } = this.$store.getters;
    if (token && accountid) {
      this.componentName = 'validate';
    }
  },
  mounted() {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
</style>
